import { Route } from 'react-router-dom';
import { Routes } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import CoreLayout from './components/layouts/coreLayout';
import IndexPage from './components/pages/indexPage';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<CoreLayout/>}>
        <Route index path="" element={<IndexPage/>}/>
      </Route>
    </Routes>
    </BrowserRouter>
  );
}

export default App;
