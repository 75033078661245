import { Component } from 'react';
import { Card } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

export default class PartnerTile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageUrl: props.imageUrl,
      webUrl: props.webUrl,
      businessName: props.businessName,
    };
  }

  render() {
    return (
      <Col sm={3}>
        <Card
          as={'a'}
          href={this.props.webUrl}
          rel="noreferrer"
          target="_blank"
          className="partner-tile"

        >
          <Card.Img src={this.props.imageUrl} alt="" height="200px" />
          <Card.Body className=''>
            <Card.Title >{this.props.businessName}</Card.Title>
          </Card.Body>
        </Card>
      </Col>
    );
  }
}
