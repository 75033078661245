import { Component } from "react";

export default class IonosPartner extends Component
{
    render() {
        return (
        <a href="https://partnernetwork.ionos.co.uk/partner/d3kdigitalltd?origin=PartnerBadge" rel="nofollow">
            <img src="https://images-2.partnerportal.ionos.co.uk/items/ae35e176-df1f-46df-84c0-fbbaac812987/profiles/94900863-2830-42df-b496-499d562c74d2/badges/normal_white_eco" alt="IONOS - Official Partner" />
        </a>
        )
    }
}