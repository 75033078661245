import { Component } from 'react';
import { Navbar } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import Logo from '../../images/D3KDigital_wt.svg';

export default class CoreLayout extends Component {
  render() {
    return (
      <>
        <Navbar as={'header'} className="justify-content-center" variant='dark'>
          <Navbar.Brand href="/" className='align-items-top'style={{textAlign:"top"}} >
            <Image className="logo-image" src={Logo} width="128" height="128" alt="D3K Digital Logo"/>™
          </Navbar.Brand>
        </Navbar>

        <Outlet />

        <footer></footer>
      </>
    );
  }
}
