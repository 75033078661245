import { Component } from 'react';
import { Container, Row } from 'react-bootstrap';
import {
  faInstagramSquare,
  faTwitterSquare,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { faSquareEnvelope } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PartnerTile from '../tiles/partnerTile';
import IonosPartner from '../tiles/ionosPartner';

library.add(faInstagramSquare, faTwitterSquare, faLinkedin, faSquareEnvelope);

export default class IndexPage extends Component {
  render() {
    return (
      <Container fluid="md" className="text-center">
        <h2 id="partners">Partners</h2>
        <Row className="justify-content-center">
          <PartnerTile
            webUrl="#partners"
            businessName="Energy Optimisation Ltd"
            imageUrl={
              'https://energyoptimisationlogos.blob.core.windows.net/logos/energyoptimisation_logo_300.jpg'
            }
          />
          <PartnerTile
            webUrl="https://biofuelenergy.co.uk"
            businessName="Biofuel Energy"
            imageUrl={
              'https://energyoptimisationlogos.blob.core.windows.net/logos/biofuel_logo.png'
            }
          />
          <PartnerTile
            webUrl="#partners"
            businessName="UK Pellets Ltd"
            imageUrl={
              'https://energyoptimisationlogos.blob.core.windows.net/logos/ukpellets_logo_300.jpg'
            }
          />
          <PartnerTile
            webUrl="#partners"
            businessName="Renewable Technology Engineering Ltd"
            imageUrl={
              'https://energyoptimisationlogos.blob.core.windows.net/logos/reneng_logo_300.jpg'
            }
          />
        </Row>

        <h2 id="socials">Socials</h2>
        <div className="social-links">
            <a
              className="social-icon"
              rel="noopener noreferrer"
              target="_blank"
              href={'https://twitter.com/D3KDigitalLtd'}
            >
              <FontAwesomeIcon icon="fa-brands fa-twitter-square" />
            </a>

            <a
              className="social-icon"
              rel="noopener noreferrer"
              target="_blank"
              href={'https://instagram.com/D3KDigitalLtd'}
            >
              <FontAwesomeIcon icon="fa-brands fa-instagram-square" />
            </a>

            <a
              className="social-icon"
              rel="noopener noreferrer"
              target="_blank"
              href={'https://www.linkedin.com/company/d3k-digital-ltd'}
            >
              <FontAwesomeIcon icon="fa-brands fa-linkedin" />
            </a>

            <a
              className="social-icon"
              rel="noopener noreferrer"
              target="_blank"
              href={'mailto:contact@d3kdigital.com'}
            >
              <FontAwesomeIcon icon="fa-solid fa-square-envelope" />
            </a>
        </div>
        <Row>
          <h2 id="about">About</h2>
          <p>
            D3K Digital is an IT Management and Software Development business
            established in 2021. We specialise in Microsoft Office 365, Azure
            Management, Web Development, and Hosting.
          </p>
          <IonosPartner />

        </Row>
      </Container>
    );
  }
}
